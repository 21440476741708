import React from 'react'
import Header from './header'
import Footer from './footer'

import arrow from '../images/arrow.svg'
import partner from '../images/partner.svg'

const Layout = ({ location, title, title2 = '', children, subTitle, kv, kvHeight = 460, kvPositionX = 'center' }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  let headings

  if (isRootPath) {
    headings = (
      <>
        <h1 className="main-heading">
          <div className="main-heading-lg">{title}</div>
          <div className="main-heading-sm">
            Change<br />
            your home<br />
            <span className="smarter">SMARTER</span>
          </div>
          <p>{subTitle}</p>
        </h1>
        <img className="sanga" src={partner} alt="私たちはヴィッセル神戸のサポートファミリーです"/>
        <div className="scroll-wrapper">
          <p className="scroll">scroll
            <img src={arrow} alt="" />
          </p>
        </div>
      </>
    )
  } else {
    headings = (
      <h1 className={`contents-heading ${title2 && 'two-stage'}`}>
        {title}
        {title2 && (
          <>
            <br className="br-sm" />
            {title2}
          </>)}
        <p>{subTitle}</p>
      </h1>
    )
  }

  return (
    <div data-is-root-path={isRootPath}>
      <section className={`main-section ${isRootPath && 'is-root'}`} style={{backgroundImage: `url(${kv})`, paddingTop: `${kvHeight / 1440 * 100}%`, backgroundPositionX: kvPositionX}}>
        <Header />
        {headings}
      </section>
      <main>{children}</main>
      <Footer />
    </div>
  )
}

export default Layout
